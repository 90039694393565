import { Injectable, computed } from '@angular/core';
import { CoreStateService } from '../_core/core-state.service';
import { initialState } from './auth-state.config';
import { AuthStateInterface } from './auth-state.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService extends CoreStateService<AuthStateInterface> {
  isLogged = computed(() => {
    const tokenExpiredAt = this.getValue('tokenExpiredAt')();

    return !!tokenExpiredAt;
  });

  constructor() {
    super(initialState);
  }
}
