import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthStateService } from '@state-management/auth-state';

const AUTHORIZATION_HEADER = 'Authorization';

export const authRequestTreatment = (
  request: HttpRequest<unknown>,
  accessTokenValue?: string,
): HttpRequest<unknown> => {
  const accessToken =
    accessTokenValue ?? inject(AuthStateService).getValue('access')();
  let headersToAdd = request.headers;

  if (mustSkipAuth(request.url)) return request;

  if (accessToken) {
    headersToAdd = addAuthorizationHeader(
      headersToAdd,
      accessToken,
      !!accessTokenValue,
    );
  }

  const authReq = request.clone({
    url: getServerUrl(request.url),
    headers: headersToAdd,
  });

  return authReq;
};

const mustSkipAuth = (url: string): boolean => /\/public/.test(url);

export const addAuthorizationHeader = (
  headers: HttpHeaders,
  value: string,
  forceOverwrite = false,
): HttpHeaders => {
  if (!forceOverwrite && headers.has(AUTHORIZATION_HEADER)) {
    return headers;
  }

  return headers.set(AUTHORIZATION_HEADER, getJWTHeaderToken(value));
};

const getJWTHeaderToken = (token: string): string => {
  return `Bearer ${token}`;
};

const getServerUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  }

  return environment.server_url + environment.api_version + url;
};
